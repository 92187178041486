

 
  
  .option {
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    float: left;
  }
  
  .sub-options {
    margin-top: 10px;
    padding-left: 10px;
  }
  
  .sub-options a {
    display: block;
    padding: 5px 0;
    color: #333;
    text-decoration: none;
  }
  
  .sub-options a:hover {
    background-color: #e4e4e4;
  }
  
  .active-link {
    background-color: #ccc !important;
  }
  
  .menu-icon,
  .sub-menu-icon {
    padding-right: 10px;
  }
  
  .menu-text {
    margin-right: 10px;
  }
  
  /* Dashboard3.css */
@media (max-width: 600px) {
  .widget-content-right .widget-numbers {
      margin: 0;
  }
}
