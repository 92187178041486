.container1 {
  display: grid;
  align-items: center; 
  grid-template-columns: .5fr 1fr 1fr;
  column-gap: 5px;
 }

 body {
  font-family: "Google Sans", Roboto, Arial, sans-serif !important;

}


 .text-glow {
  color: #fff;
  text-shadow: 0 0 10px rgb(104, 227, 229), 0 0 20px rgb(97, 173, 236), 0 0 30px rgb(71, 180, 224);
}

.price-tag {
  display: block;
  font-size: 16px;
  color: #3c3e40;
  margin-top: 10px;
  font-weight: 500;
  background-color: aliceblue;
}

.announcement-card {
  margin: 20px 0;
}
.announcement-card .card-body {
  padding: 20px;
}
.announcement-card .card-header {
  background-color: #f8f9fa;
  border-bottom: none;
}
.announcement-card .card-title {
  font-size: 1.25rem;
}
.announcement-card .author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.announcement-card .author img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.announcement-card .comment-box {
  margin-top: 20px;
}


.spinnerDot {
width: 1rem;
height: 1rem;
border: 0.2em solid rgba(0, 0, 0, 0.1);
border-radius: 50%;
border-top-color: #007bff;
animation: spin 1s linear infinite;
margin-right: 0.5rem;
}

.greenDot {
width: 1rem;
height: 1rem;
background-color: green;
border-radius: 50%;
margin-right: 0.5rem;
}

@keyframes spin {
to { transform: rotate(360deg); }
}



.section-title {
position: relative;
padding-bottom: 1.5rem; 
}

.try-now-link {
position: absolute;
right: 0;
bottom: 6.5rem;
font-size: 0.875rem;
text-decoration: none;
color: #007bff; /* Adjust the color as needed */
white-space: nowrap; /* Prevent text from wrapping */
}

.try-now-link i {
font-size: 0.75rem; /* Adjust the icon size as needed */
}

@media (max-width: 768px) {
.section-title {
    position: static;
    text-align: center;
}

.try-now-link {
    position: static;
    display: inline-block;
    margin-top: 1rem; /* Add some space above the link */
    font-size: 0.75rem; /* Adjust the size for smaller screens */
}
}


.icon-boundary {
width: 80px;
height: 80px;
border: 2px solid #007bff; /* Adjust the color as needed */
border-radius: 50%; /* Makes the boundary circular */
display: flex;
align-items: center;
justify-content: center;
margin: 0 auto; /* Center the icon boundary */
}

.tm-icon {
color: #007bff; /* Adjust the icon color as needed */
}

._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}

.color{
  background-color: rgb(97, 182, 232);
}

.page-title {
  position: relative;
  background: none;
}

.page-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://static.vecteezy.com/system/resources/thumbnails/008/800/859/original/neural-network-ai-technology-cloud-computing-bits-internet-5g-blue-background-information-back-free-video.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1; /* Adjust transparency */
  z-index: -1; /* Send behind text */
}

.light-background {
  position: relative;
}



.carousel-container {
  max-width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 2); /* Adjust as per the number of items */
}

.carousel-slide {
  flex: 0 0 calc(100% / 3); /* Three items per row */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card img {
  width: 100%;
  height: auto;
}

.card-footer {
  background-color: #f8f9fa; /* Light gray footer */
  padding: 10px;
  text-align: center;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.card-text {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0 0;
}

/* Add spacing between title and description */
.card-footer h5 {
  margin-bottom: 5px;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1000;
}

.carousel-btn.prev {
  left: 10px;
}

.carousel-btn.next {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}


.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  align-items: stretch; /* Ensure items stretch to match the tallest element */
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  flex: 1 0 calc(100% / 3); /* Adjust for three slides per view */
  box-sizing: border-box;
  padding: 10px;
}

.card {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Space content evenly */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.card img {
  max-height: 200px; /* Optional: Limit image height */
  object-fit: cover; /* Ensure images maintain aspect ratio */
  border-radius: 8px 8px 0 0;
}

.card .card-title {
  margin-top: 10px;
  font-size: 1.25rem;
}

.card .card-text {
  flex-grow: 1; /* Push the rest of the content to fill space */
}


/* General Styles */
.header {
  background: #fff;
  width: 100%;
  z-index: 1000;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo img {
  max-height: 40px;
}

.navmenu {
  display: flex;
  align-items: center;
}

.navmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}



.navmenu .dropdown ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.navmenu .dropdown ul.show {
  display: block;
}

.mobile-nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 1200px) {
  .navmenu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .navmenu.navmenu-mobile {
    display: flex;
  }

  .navmenu ul {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 20px;
  }

  .navmenu ul li {
    text-align: center;
    padding: 10px;
  }
  .navmenu ul :hover {
    background-color: #f8f9fa;
  }

  .mobile-nav-toggle {
    display: block;
  }
}
/* Add rotation to the dropdown icon when it's open */
.toggle-dropdown.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

/* Ensure dropdown menus are initially hidden */
.dropdown ul {
  display: none;
}

/* Display dropdown when it's open */
.dropdown ul.show {
  display: block;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.zoom-image {
  width: 100%;
  height: 400px;
  transition: transform 0.3s ease;
}

.image-wrapper:hover .zoom-image {
  transform: scale(1.1); /* Zoom effect */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
}

.overlay h4 {
  font-size: 1.5rem;
}

.image-wrapper:hover .overlay {
  opacity: 1; 
}


.navmenu .dropdown ul {
  display: none;
}
.navmenu .dropdown.active ul {
  display: block;
}


.user-dropdown-container {
  position: relative; /* Set as reference for dropdown positioning */
  display: inline-block; /* Align with other elements */
}

.user-dropdown-toggle {
  cursor: pointer;
  color: #333; /* Text color */
  text-decoration: none;
  font-weight: 500;
}

.user-dropdown-menu {
  position: absolute; /* Floating dropdown */
  top: 100%; /* Position below the toggle */
  right: 0; /* Align to the right */
  display: none; /* Hidden by default */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  list-style: none;
  padding: 10px 0;
  border-radius: 4px;
  min-width: 150px;
}

.user-dropdown-container .dropdown-open {
  display: block; /* Show the dropdown when active */
}

.user-dropdown-menu li {
  padding: 8px 16px;
}

.user-dropdown-menu li a {
  color: #333;
  text-decoration: none;
  display: block;
}

.user-dropdown-menu li a:hover {
  background-color: #f4f4f4;
}


.user-dropdown-menu {
  display: none;
}

.user-dropdown-menu.dropdown-open {
  display: block;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  font-size: 32px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #007bff;
}

.icon-wrapper:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.bg-secondary {
  background-color: #f0f0f0;
}
.text-white {
  color: #ffffff;
}

.course-items-area {
  display: flex;
  flex-wrap: wrap;
  
}

.mix {
  display: flex;
  flex-direction: column;
}

.course-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures equal spacing for inner elements */
  height: 100%; /* Makes the card stretch */

}


.course-info {
  flex: 1; /* Ensures the info section stretches to fill space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.set{
  background-repeat: no-repeat;
	background-size: contain;
	background-position: top center;
 
}

.event .title {
  margin: 0px 0 0;
}

.event .nav-content {
  float: right;
  width: 70%;
}

.event .btn {
  background: #3B71CA;
}

.event .nav-content strong {
  display: block;
  font-family: Montserrat;
  font-size: 20px;
  color: #3B71CA;
}

.event nav > div a.nav-item.nav-link.active .event .spicel-features {
  background: #fafaff;
}

.event .nav-fill .nav-item {
  text-align: left;
  text-transform: uppercase;
  margin: 0 5px;
  font-size: 12px;
  margin-right: 16px;
}

.event .nav-fill .nav-item text-t i {
  margin-right: 10px;
}

.event .nav-fill .nav-item i::before {
  font-size: 26px;
  margin-right: 13px;
  position: relative;
  top: 4px;
}

.event nav > .nav.nav-tabs {
  border: none;
  color: #fff;
  border-radius: 0;
  flex-wrap: inherit;
  padding: 0 30px;
}

.event .nav-item {
  margin: 0 0;
}

.event a.nav-item.nav-link.active {
  background: #3B71CA !important;
  color: #fff !important;
}

.event a.nav-item.nav-link.active strong {
  color: #fff;
}

.event nav > div a.nav-item.nav-link,
.event nav > div a.nav-item.nav-link.active {
  border: none;
  padding: 18px 25px;
  color: #47759d;
  border-radius: 0;
  background: #fff;
  position: relative;
  background: #f5faff;
}

.event nav > div a.nav-item.nav-link.active:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #e7015e transparent transparent;
  position: absolute;
  left: 0;
  bottom: -15px;
  content: '';
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-color: transparent #3B71CA transparent transparent;
}

.event .tab-content {
  padding: 50px 25px 0 !important;
}

.event .user h5 {
  margin-top: 20px;
}

.event .user p {
  font-weight: bold;
  color: #3B71CA;
}

.event .user ul {
  padding-top: 10px;
  border-top: 1px solid #edf5ff;
}

.event .user ul li {
  margin-top: 10px;
}

.event .user ul li i {
  margin-right: 5px;
}

.event-list-content {
  margin-left: 50px;
  margin-right: 20px;
  padding: 50px;
  position: relative;
  border: 10px solid #edf5ff;
  transition: all 0.3s ease 0s;
}

.event-list-content:hover {
  border: 10px solid #3B71CA;
}

.event-list-content .crical {
  position: absolute;
  background: #3B71CA;
  right: -66px;
  bottom: -73px;
  font-size: 30px;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 50px;
}

.event-list-content h2 {
  margin-bottom: 30px;
}

.event-list-content .btn {
  background: none;
  border: 2px solid #edf5ff;
  color: #47759d;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.event-list-content .btn:hover {
  background: #3B71CA;
  border: 2px solid #3B71CA;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  box-shadow: 0px 10px 19px 0px #3B71CA66 !important;
}

.event-list-content ul li {
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #3B71CA;
}

.event-list-content ul li i {
  margin-right: 5px;
}

.event nav > div a.nav-item.nav-link .lgt-icon {
  display: none;
}

.event nav > div a.nav-item.nav-link.active .lgt-icon {
  display: inline-block;
}

.event nav > div a.nav-item.nav-link.active .drk-icon {
  display: none;
}

.event nav > div a.nav-item.nav-link.active .drk-icon {
  display: none;
}

.img, img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}

.event .user h5 {
  margin-top: 20px;
}
a, h1, h2, h3, h4, h5, h6, p, span {
  overflow-wrap: break-word;
}
h5 {
  font-size: 18px;
}


.event .user p {
  font-weight: bold;
  color: #3B71CA;
}
.event .user ul {
  padding-top: 10px;
  border-top: 1px solid #edf5ff;
}
ul {
  margin: 0px;
  padding: 0px;
}

.event-list-content .crical {
  position: absolute;
  background: #3B71CA;
  right: -66px;
  bottom: -73px;
  font-size: 30px;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 50px;
}
.fix {
  overflow: hidden;
}

.event nav > div a.nav-item.nav-link.active:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #3B71CA transparent transparent;
  position: absolute;
  left: 0;
  bottom: -15px;
  content: '';
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  border-color: transparent #3B71CA transparent transparent;
}

/* Hide dropdown content by default */
.navmenu ul ul {
  display: none;
  position: relative; /* Avoid overlapping */
  padding-left: 10px; /* Add indentation for nested items */
  background-color: #fff; /* Optional: Add background color for better visibility */
  z-index: 1; /* Ensure dropdown stays above other elements */
}

/* Show dropdown when active */
.navmenu ul .dropdown-open {
  display: block;
}

/* Add spacing between dropdown items */
.navmenu ul li {
  margin: 5px 0;
}

/* Mobile-specific dropdown styles */
.navmenu-mobile ul ul {
  padding-left: 20px; /* Indent for better UI in mobile view */
}

.navmenu-mobile ul ul li {
  margin: 5px 0; /* Add vertical spacing between nested items */
}

/* Fix overlapping by limiting height and scrolling */
.navmenu ul ul {
  max-height: 300px; /* Optional: Limit max height */
  overflow-y: auto; /* Add scroll if content exceeds height */
}


* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

p {
color: grey;
}

#heading {
text-transform: uppercase;
color: #3B71CA;
font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;

  /*stacking fieldsets above each other*/
  position: relative;
}

.form-card {
text-align: left;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input, #msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  background-color: #ECEFF1;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus, #msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673AB7;
  outline-width: 0;
}

/*Next Buttons*/
#msform .action-button {
  width: 100px;
  background: #3B71CA;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  margin: 10px 0px 10px 5px;
  float: right;
}

#msform .action-button:hover, #msform .action-button:focus {
  background-color: #2c90e8;
}

/*Previous Buttons*/
#msform .action-button-previous {
  width: 100px;
  background: #010101;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
  background-color: #000000;
}

/*The background card*/
.card {
  z-index: 0;
  border: none;
  position: relative;
}

/*FieldSet headings*/
.fs-title {
  font-size: 25px;
  color: #3B71CA;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
color: #3B71CA;
  font-weight: normal;
}

/*Step Count*/
.steps {
font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

/*Field names*/
.fieldlabels {
color: gray;
text-align: left;
}

/*Icon progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #3B71CA;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 16%;
  float: left;
  position: relative;
  font-weight: 400;
}

/*Icons in the ProgressBar*/
#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

/*Icon ProgressBar before any progress*/
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before, #progressbar li.active:after {
  background: #3B71CA;
}

/*Animated Progress Bar*/
.progress {
height: 20px;
}

.progress-bar {
background-color: #3B71CA;
}

/*Fit image in bootstrap div*/
.fit-image{
  width: 100%;
  object-fit: cover;
}


.avatar--md .avatar__content img{
  width:40px;
  height:40px;
}

/* Custom styling for form labels and select elements */
.fieldlabels {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

/* Custom style for the dropdown background */
.custom-dropdown {
  background-color: white;  /* Set the background to white */
  margin-bottom: 20px;  /* Space between the dropdowns */
  padding: 8px;  /* Padding inside the dropdown */
  font-size: 16px;  /* Adjust font size */
  border-radius: 4px;  /* Rounded corners */
  border: 1px solid #ccc;  /* Light border */
}

.inbox .inbox-menu ul {
  margin-top: 30px;
  padding: 0;
  list-style: none
}

.inbox .inbox-menu ul li {
  height: 30px;
  padding: 5px 15px;
  position: relative
}

.inbox .inbox-menu ul li:hover,
.inbox .inbox-menu ul li.active {
  background: #e4e5e6
}

.inbox .inbox-menu ul li.title {
  margin: 20px 0 -5px 0;
  text-transform: uppercase;
  font-size: 10px;
  color: #d1d4d7
}

.inbox .inbox-menu ul li.title:hover {
  background: 0 0
}

.inbox .inbox-menu ul li a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #3d3f42
}

.inbox .inbox-menu ul li a i {
  margin-right: 10px
}

.inbox .inbox-menu ul li a .label {
  position: absolute;
  top: 10px;
  right: 15px;
  display: block;
  min-width: 14px;
  height: 14px;
  padding: 2px
}

.inbox ul.messages-list {
  list-style: none;
  margin: 15px -15px 0 -15px;
  padding: 15px 15px 0 15px;
  border-top: 1px solid #d1d4d7
}

.inbox ul.messages-list li {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px
}

.inbox ul.messages-list li a {
  color: #3d3f42
}

.inbox ul.messages-list li a:hover {
  text-decoration: none
}

.inbox ul.messages-list li.unread .header,
.inbox ul.messages-list li.unread .title {
  font-weight: 700
}

.inbox ul.messages-list li:hover {
  background: #e4e5e6;
  border: 1px solid #d1d4d7;
  padding: 9px
}

.inbox ul.messages-list li:hover .action {
  color: #d1d4d7
}

.inbox ul.messages-list li .header {
  margin: 0 0 5px 0
}

.inbox ul.messages-list li .header .from {
  width: 49.9%;
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis
}

.inbox ul.messages-list li .header .date {
  width: 50%;
  text-align: right;
  float: right
}

.inbox ul.messages-list li .title {
  margin: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden!important;
  text-overflow: ellipsis
}

.inbox ul.messages-list li .description {
  font-size: 12px;
  padding-left: 29px
}

.inbox ul.messages-list li .action {
  display: inline-block;
  width: 16px;
  text-align: center;
  margin-right: 10px;
  color: #d1d4d7
}

.inbox ul.messages-list li .action .fa-check-square-o {
  margin: 0 -1px 0 1px
}

.inbox ul.messages-list li .action .fa-square {
  float: left;
  margin-top: -16px;
  margin-left: 4px;
  font-size: 11px;
  color: #fff
}

.inbox ul.messages-list li .action .fa-star.bg {
  float: left;
  margin-top: -16px;
  margin-left: 3px;
  font-size: 12px;
  color: #fff
}

.inbox .message .message-title {
  margin-top: 30px;
  padding-top: 10px;
  font-weight: 700;
  font-size: 14px
}

.inbox .message .header {
  margin: 20px 0 30px 0;
  padding: 10px 0 10px 0;
  border-top: 1px solid #d1d4d7;
  border-bottom: 1px solid #d1d4d7
}

.inbox .message .header .avatar {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 34px;
  width: 34px;
  float: left;
  margin-right: 10px
}

.inbox .message .header i {
  margin-top: 1px
}

.inbox .message .header .from {
  display: inline-block;
  width: 50%;
  font-size: 12px;
  margin-top: -2px;
  color: #d1d4d7
}

.inbox .message .header .from span {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #3d3f42
}

.inbox .message .header .date {
  display: inline-block;
  width: 29%;
  text-align: right;
  float: right;
  font-size: 12px;
  margin-top: 18px
}

.inbox .message .attachments {
  border-top: 3px solid #e4e5e6;
  border-bottom: 3px solid #e4e5e6;
  padding: 10px 0;
  margin-bottom: 20px;
  font-size: 12px
}

.inbox .message .attachments ul {
  list-style: none;
  margin: 0 0 0 -40px
}

.inbox .message .attachments ul li {
  margin: 10px 0
}

.inbox .message .attachments ul li .label {
  padding: 2px 4px
}

.inbox .message .attachments ul li span.quickMenu {
  float: right;
  text-align: right
}

.inbox .message .attachments ul li span.quickMenu .fa {
  padding: 5px 0 5px 25px;
  font-size: 14px;
  margin: -2px 0 0 5px;
  color: #d1d4d7
}

.inbox .contacts ul {
  margin-top: 30px;
  padding: 0;
  list-style: none
}

.inbox .contacts ul li {
  height: 30px;
  padding: 5px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis!important;
  position: relative;
  cursor: pointer
}

.inbox .contacts ul li .label {
  display: inline-block;
  width: 6px;
  height: 6px;
  padding: 0;
  margin: 0 5px 2px 0
}

.inbox .contacts ul li:hover {
  background: #e4e5e6
}


/* Sidebar styling */
.sidebar {
  width: 260px;
  background-color: #ffffff;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  display: flex;
  flex-direction: column;

}

.sidebar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 20px;
  font-weight: bold;
  color: #1967d2;
  border-bottom: 1px solid #e0e0e0;
}

.sidebar a {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  border-radius: 5px;

  transition: background-color 0.3s;
}

.sidebar a.active, .sidebar a:hover {
  background-color: #e8f0fe;

}

.sidebar a .icon {
  margin-right: 15px;
  font-size: 18px;
  color: #757575;
}

.sidebar h3 {
  margin: 15px 20px;
  font-size: 14px;
  color: #888;
  font-weight: bold;
}

.sidebar .bottom-link {

  border-top: 1px solid #e0e0e0;
  padding-top: 10px;

}

/* Main content styling */
.main-content {
  flex: 1;
  padding: 20px;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

}

.topbar h1 {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.topbar button {
  font-size: 20px;

  color: #fff;
  border: none;
  border-radius: 50%;
  height: 40px;

  cursor: pointer;
}

/* Card grid styling */
.card-grid {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  height: 80px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.card-content {
  padding: 15px;
}

.card-content .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Card colors */
.card-header.blue {
  background-color: #1967d2;
}

.card-header.orange {
  background-color: #f9ab00;
}

.card-header.green {
  background-color: #34a853;
}

.card-header.purple {
  background-color: #6a1b9a;
}

.card-header.red {
  background-color: #d93025;
}

/* Styling for the avatar */
.avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3B71CA;
  color: white;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  margin-right: 10px;
}


.course-item:hover {
  background-color: #f1f1f1;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between arrows and heading */
}

.calendar-header h2 {
  font-size: 20px;
  font-weight: bold;
}



.calendar-header button  {
 
  border: none;
  background: none;
  color: #333; /* Dark color */
  font-size: 30px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin-top: 10px; 
}


.calendar-header button:hover {
  color: #000; /* Darker on hover */
}




/* Calendar Grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  height: 550px; /* Set a fixed height for the calendar */
}

.calendar-day {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;

}

.day-header {
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.day-content {
  flex: 1; /* Make the content stretch to fill the space */
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #555;
  overflow-y: auto; /* Add scrolling if content overflows */
}

.day-content p {
  margin: 0;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}
.thumb-sm {
  height: 36px;
  width: 36px;
}

.task-detail .task-dates li {
  width: 50%;
  float: left
}

.task-detail .task-tags .bootstrap-tagsinput {
  padding: 0;
  border: none
}

.task-detail .assign-team a {
  display: inline-block;
  margin: 5px 5px 5px 0
}

.task-detail .files-list .file-box {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  padding: 2px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box
}

.task-detail .files-list .file-box img {
  line-height: 70px
}

.task-detail .files-list .file-box p {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.add-new-plus {
  height: 32px;
  text-align: center;
  width: 32px;
  display: block;
  line-height: 32px;
  color: #98a6ad;
  font-weight: 700;
  background-color: #e3eaef;
  border-radius: 50%
}

.project-sort-item .form-group {
  margin-right: 30px
}

.project-sort-item .form-group:last-of-type {
  margin-right: 0
}

.project-box {
  position: relative
}

.project-box .badge {
  position: absolute;
  right: 20px
}

.project-box h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden
}

.project-box ul li {
  padding-right: 30px
}

.project-box .project-members a {
  margin: 0 0 10px -12px;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02)
}

.project-box .project-members a:first-of-type {
  margin-left: 0
}

.company-card .company-logo {
  float: left;
  height: 60px;
  width: 60px;
  border-radius: 3px
}

.company-card .company-detail {
  margin: 0 0 50px 75px
}
.text-muted {
  color: #98a6ad!important;
}

p {
  line-height: 1.6;
  font-size: 14px;
}

.bootstrap-tagsinput .label-info {
  background-color: #02c0ce;
  display: inline-block;
  padding: 4px 8px;
  font-size: 13px;
  margin: 3px 1px;
  border-radius: 3px;
}


/* Add this CSS to your gallery style */
.gallery-img {
  height: 250px;  /* Set the height you want for all images */
  object-fit: cover;  /* Ensures that the images fill the container while maintaining aspect ratio */
  width: 100%;  /* Makes sure the images fill the width of the container */
}


.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Topbar */
.topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px; /* Fixed height */
  background-color: #f8f9fa; /* Light background color */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000; /* Ensure it stays above everything */
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 64px; /* Aligns below the topbar */
  left: 0;
  width: 240px; /* Fixed width for the sidebar */
  height: calc(100vh - 64px); /* Full height minus the topbar */
  background-color: #f8f9fa; /* Light background color */
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 16px;
  overflow-y: auto; /* Scrollable if content overflows */
  z-index: 999; /* Below the topbar */
}

/* Main Content */
.main-content {
  margin-left: 270px; /* Same as the sidebar's width */
margin-top: 20px;
  padding: 26px;
}

/* Parent calendar-day container */
.calendar-day {
  position: relative; /* Allow for child elements to be positioned relative to this */
  overflow: visible; /* Ensures the header can extend outside */
}

/* Current day header style */
.current-day-header {
  font-size: 1rem; /* Larger font size */
  font-weight: bold; /* Make the text bold */
  color: #ffffff; /* White text */
  background-color: #ff5733; /* Distinct background color */
  padding: 10px 0;
  text-align: center; /* Center align the text */
  width: 100%; /* Fixed width for circular shape */
  height: 50px; /* Fixed height for circular shape */
  line-height: 40px; /* Align the text vertically */
  position: absolute; /* Absolute positioning to move it freely */
  top: -20px; /* Moves the header upwards (adjust this value to suit) */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Offset by 50% of the element's width to perfectly center */
}

.current-day-header-monthly{
  font-size: 1rem; /* Larger font size */
  font-weight: bold; /* Make the text bold */
  color: #ffffff; /* White text */
  background-color: #ff5733; /* Distinct background color */
  padding: 10px 0;
  text-align: center; /* Center align the text */
  width: 100%; /* Fixed width for circular shape */
  height: 50px; /* Fixed height for circular shape */
  line-height: 40px; /* Align the text vertically */
  position: absolute; /* Absolute positioning to move it freely */
  
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}

.list-group-item-action {
    width: 100%;
    color: #4d5154;
    text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #4d5154;
    text-decoration: none;
    background-color: #f4f6f9;
}
.list-group-item-action:active {
    color: #8e9194;
    background-color: #eef0f3;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #ffffff;
    border: 1px solid #eef0f3;
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: #6d7174;
    pointer-events: none;
    background-color: #ffffff;
}
.list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #1b68ff;
    border-color: #1b68ff;
}
.list-group-item + .list-group-item {
    border-top-width: 0;
}
.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}
.list-group-flush > .list-group-item {
    border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #0e3685;
    background-color: #bfd5ff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #0e3685;
    background-color: #a6c4ff;
}
.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0e3685;
    border-color: #0e3685;
}

.list-group-item-secondary {
    color: #0a395d;
    background-color: #bdd6ea;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #0a395d;
    background-color: #aacae4;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #0a395d;
    border-color: #0a395d;
}

.list-group-item-success {
    color: #107259;
    background-color: #c0f5e8;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #107259;
    background-color: #aaf2e0;
}
.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #107259;
    border-color: #107259;
}

.list-group-item-info {
    color: #005d83;
    background-color: #b8eafe;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #005d83;
    background-color: #9fe3fe;
}
.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #005d83;
    border-color: #005d83;
}

.list-group-item-warning {
    color: #855701;
    background-color: #ffe7b8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #855701;
    background-color: #ffde9f;
}
.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #855701;
    border-color: #855701;
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #7f8081;
    background-color: #fcfcfd;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #7f8081;
    background-color: #ededf3;
}
.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7f8081;
    border-color: #7f8081;
}

.list-group-item-dark {
    color: #17191c;
    background-color: #c4c5c6;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #17191c;
    background-color: #b7b8b9;
}
.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #17191c;
    border-color: #17191c;
}